
import * as Components from "./Playa"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "playa"
}
